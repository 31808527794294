
























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SearchViewModel } from '../../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'dao-card': () => import('@/modules/community/common/components/dao-card.vue'),
  },
})
export default class Daos extends Vue {
  @Inject() vm!: SearchViewModel
}
